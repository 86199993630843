import React from 'react';
import ReceiptSVG from '../../components/receiptSVG';
import gsap from "gsap";
import { useRef, useEffect } from 'react';
import ILEIntroAnimation from '../../components/ItiliteExp/IntroAnimation';
import Layout from '../../components/layout';

import { ScrollTrigger } from "gsap/ScrollTrigger";
import FigmaAnimation from '../../components/ItiliteExp/FigmaAnimation';
import MansoryBlocks from '../../components/ItiliteExp/MansoryBlocks';
import DesignSys from '../../components/ItiliteExp/DesignSys';
import ScaningAnimation from '../../components/ItiliteExp/ScaningAnimation';



const ItiliteExpense = () => {

    const tl = useRef();
    const tl2 = useRef();
    const figmaTl = useRef();
    const introAnimationRef = useRef();
    const contentContainer = useRef();
    const workTypeArrayRef = useRef();
    const largeIphoneRef = useRef();
    workTypeArrayRef.current = [];

    const addToworkTypeArrayRef = (el) => {
        if (el && !workTypeArrayRef.current.includes(el)) {
            workTypeArrayRef.current.push(el);
        }
    };



    useEffect(() => {


        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger);
            gsap.core.globals("ScrollTrigger", ScrollTrigger);

        }



        
        tl.current = gsap.timeline({
            paused: true,
            scrollTrigger: {
                markers: false,
                trigger: introAnimationRef.current,
                scrub: 1,
                start: '+=60'
            }
        });
        tl.current.set([introAnimationRef.current], {
            yPercent:0
        })
        tl.current.to([introAnimationRef.current], {
            yPercent: 20,
            opacity:0.4,
            ease: 'none'
        })



        // tl.current.to([contentContainer.current], {
        //     yPercent: -20,
        //     ease: "none"
        // }, 0)

        gsap.from(workTypeArrayRef.current, {
            y:100,
            ease:'expo.inOut',
            duration:1.4,
            opacity:0,
            stagger: 0.1,
            scrollTrigger: {
                trigger:workTypeArrayRef.current[0],
                markers:false
            }
        })




        tl2.current = gsap.timeline({
            paused: true,
            scrollTrigger: {
                trigger: largeIphoneRef.current,
                scrub: 1.7,
                end: "+=2000",
                markers: false
            }
        });
        tl2.current.set([largeIphoneRef.current], {
            yPercent:0,
            rotation:-1
          
        })
        tl2.current.to([largeIphoneRef.current], {
            yPercent: -40,
            opacity:1,
            rotation:2,
            ease: 'none'
        })



        // gsap.to([largeIphoneRef.current], {
        //     yPercent:-20,
        //     scrollTrigger: {
        //         trigger: largeIphoneRef.current,
        //         scrub: 2,

        //     }
        // })




        // figmaTl.current = gsap.timeline({
        //     scrollTrigger: {
        //         trigger:
        //     }
        // })

        return () => {
            //do nothing
        }
    }, []);


    let mouseHoverRef = useRef({
        x:1
      });


    return (
        <Layout mouseHoverState={mouseHoverRef.current}>
            <div className="bg-black">
                <div ref={introAnimationRef}><ILEIntroAnimation /></div>
                <div className="bg-black pt-48 pb-0 z-30" ref={contentContainer}>

                    <div className="max-w-6xl mx-auto flex px-8 md:px-0 flex-col md:flex-row">
                        <div className="md:w-1/2">
                            <h1 className="text-orange-500 text-xl md:text-4xl font-mediumstr">Expenses remibursment made faster and easy.</h1>
                            <p className="font-normalstr text-sm md:text-lg text-white mt-4 tracking-wide leading-relaxed">How we worked with India's leading travel software and integrated Expenses to make employees file expenses on the go.</p>
                        </div>

                        <div className="md:w-1/2 flex md:items-center md:justify-center mt-12 md:mt-0">
                            <img className="md:w-3/12" src="/itilite-logo-1.png" />
                        </div>

                    </div>

                    <div className="max-w-6xl mx-auto mt-20 pb-10 flex flex-col md:flex-row justify-between px-8 md:px-0">


                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Client Website
                            </div>
                            <a href="#" className="text-gray-500 text-sm">
                               Itilite.com
                            </a>
                        </div>


                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Deliverables
                            </div>
                            <div className="text-gray-500 flex flex-wrap text-sm">
                                <span className=" font-light rounded-lg px-2 py-2 border-gray-800 border-2 mr-3 bg-black shadow-default">Experience Design</span>
                                <span className=" font-light mt-2 md:mt-0 rounded-lg px-2 py-2 mr-3 border-gray-800 border-2 bg-black shadow-default">Interface Design</span>
                            </div>
                        </div>

                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Tools used
                            </div>
                            <div className="text-gray-500  text-sm">
                                <span className=" font-light rounded-lg px-2 py-2 border-gray-800 border-2 mr-3 bg-black shadow-default">Figma</span>
                                <span className=" font-light mt-2 md:mt-0 rounded-lg px-2 py-2 mr-3 border-gray-800 border-2 bg-black shadow-default">Marvel</span>
                            </div>
                        </div>


                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Time Duration
                            </div>
                            <div className="text-gray-500">
                               8 months
                            </div>
                        </div>



                    </div>


                    <ScaningAnimation />

                    <div className="max-w-6xl mx-auto flex flex-col md:flex-row md:my-24 md:py-24 py-8 px-8">
                        <div className="md:w-1/2 w-full flex-1 flex md:items-center md:justify-center">
                            <h2 className="text-2xl mb-3 md:text-5xl text-white font-boldstr">Our Approch</h2>
                        </div>
                        <div className="flex-1">
                            <p className="md:text-lg text-sm font-normalstr text-gray-300">Any good approach considers both the requirements to build what’s designed and how the end user will experience the product.<br/><br/>
                                We used a modular design framework that would ease implementation for the Itilite's engineering team and empower our designers to be really creative.</p>
                        </div>

                        
                    </div>

                    <div className="max-w-6xl mx-auto mb-42">
                        <MansoryBlocks />
                    </div>


                    <div className="large-iphone-screenShot max-w-lg mx-auto relative mt-4 md:mt-32 px-8 md:px-0">
                        <img ref={largeIphoneRef} src="/client/itilite-exp/reportPageIphoneScreenshot2.png" className="w-full" />
                    </div>


                    

                    <DesignSys />

                    <FigmaAnimation />
                    
                    {/* <div ref={figmaWireFrameAreaRef} className="text-6xl text-white mt-24">
                        <div className="" style={{ backgroundImage:'url(/client/itilite-exp/figma-cover.png)', backgroundSize:'cover', height:'450px' }}>
                        </div>
                    </div> */}



                </div>

        

            </div>


        
         
        </Layout>

    )
}


export default ItiliteExpense;